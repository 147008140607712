import * as React from "react";
import Form from "components/organisims/Form";
import { ad } from "settings/FormSettings";
import * as Router from "@reach/router";
export interface ShowProps extends Router.RouteComponentProps {}

export default function Ad(props: ShowProps) {
  const { action } = Router.useParams();
  return (
    <>
      <Form dataType="ad" formParams={ad} />
    </>
  );
}
