import React from "react";
import Table from "components/molecures/Table";
import * as Router from "@reach/router";
export interface ShowProps extends Router.RouteComponentProps {}

function ArticlePickups(props: ShowProps) {
  return <Table type="articlePickups" />;
}

export default ArticlePickups;
