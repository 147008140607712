import React from "react";
import Table from "components/molecures/Table";
import * as Router from "@reach/router";
import { API, graphqlOperation } from "aws-amplify";
import { listArticleTagNews } from "graphql/queries";
import common from "features/common/commonSlice";
import { useDispatch } from "react-redux";

export interface ShowProps extends Router.RouteComponentProps {}

function Tags(props: ShowProps) {
  const dispatch = useDispatch();
  const customQuery = `
  query MyQuery($nextToken: String, $limit: Int) {
    listTags(nextToken: $nextToken, limit: $limit)  {
      items {
        name
        id
        priority
        slug
        createdAt
        updatedAt
      }
      nextToken
    }
  }
  `;
  const variables = {
    limit: 10,
  };
  return (
    <Table
      type="tags"
      customQuery={customQuery}
      variables={variables}
      enableDelete={async (rowData: any) => {
        const res = await API.graphql(
          graphqlOperation(listArticleTagNews, {
            filter: { tagID: { eq: rowData.id } },
          })
        );
        if (res.data.listArticleTagNews.items.length === 0) {
          return true;
        }

        dispatch(
          common.actions.fetchFailure({
            errors: [
              {
                message:
                  "記事が設定されているタグは削除できません。記事のタグを外してから削除してください。",
              },
            ],
          })
        );
        return false;
      }}
    />
  );
}
export default Tags;
