import * as React from "react";
import Form from "components/organisims/Form";
import { topImage } from "settings/FormSettings";
import * as Router from "@reach/router";
export interface ShowProps extends Router.RouteComponentProps {}

export default function Page(props: ShowProps) {
  return (
    <>
      <Form dataType="topImage" formParams={topImage} />
    </>
  );
}
