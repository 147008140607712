import * as React from "react";
import Form from "components/organisims/Form";
import { page } from "settings/FormSettings";
import * as Router from "@reach/router";
export interface ShowProps extends Router.RouteComponentProps {}

export default function Page(props: ShowProps) {
  const { action } = Router.useParams();
  return (
    <>
      <Form dataType="page" formParams={page} />
    </>
  );
}
