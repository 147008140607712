import * as React from "react";
import Form from "components/organisims/Form";
import { partialHtml } from "settings/FormSettings";
import * as Router from "@reach/router";
export interface ShowProps extends Router.RouteComponentProps {}

export default function PartialHtml(props: ShowProps) {
  const { action } = Router.useParams();

  return (
    <Form dataType="partialHtml" formParams={partialHtml} useVersion={false} />
  );
}
