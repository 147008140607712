import React from "react";
import Table from "components/molecures/Table";
import * as Router from "@reach/router";
import { API, graphqlOperation } from "aws-amplify";
import { searchArticles } from "graphql/queries";
import { useDispatch } from "react-redux";
import common from "features/common/commonSlice";
export interface ShowProps extends Router.RouteComponentProps {}

function Categores(props) {
  const dispatch = useDispatch();
  return (
    <Table
      type="categorys"
      childList="articles"
      enableDelete={async (rowData: any) => {
        const res = await API.graphql(
          graphqlOperation(searchArticles, {
            filter: { categoryID: { eq: rowData.id } },
          })
        );
        if (res.data.searchArticles.items.length === 0) {
          return true;
        }
        dispatch(
          common.actions.fetchFailure({
            errors: [{ message: "先に紐づく記事を削除してください。" }],
          })
        );
        return false;
      }}
    />
  );
}
export default Categores;
