import * as React from "react";
import Article from "components/pages/front/Article";
import * as Router from "@reach/router";
import { API, graphqlOperation } from "aws-amplify";
import { getArticle, listAdBanners } from "graphql/queries";
import { AdBanner, UpdateArticleInput as ArticleType } from "API";
import { isWithinInterval } from "date-fns";
import { AdBannersType } from "../../../../../types/adBanners";

// ------------------------------------------------------------

const sortByPriority = (items: AdBanner[]): AdBanner[] => {
  return items.slice().sort((a: any, b: any) => {
    const aPriority = a.priority ?? 0;
    const bPriority = b.priority ?? 0;
    return bPriority - aPriority;
  });
};

// ------------------------------------------------------------

export default function PreviewArticle() {
  const { id } = Router.useParams();
  const [article, setArticle] = React.useState<ArticleType | undefined>();
  const [relatedArticles, setRelatedArticles] = React.useState<ArticleType[]>(
    []
  );
  const [adBannersState, setAdBannersState] = React.useState<AdBannersType>({
    topheader: [],
    topfooter: [],
    ad: [],
    pr: [],
    articlefooter: [],
  });

  React.useEffect(() => {
    (async () => {
      // 記事情報とバナーを同時取得
      const [articleRes, adBannersRes] = await Promise.all([
        API.graphql(
          graphqlOperation(getArticle, {
            id,
          })
        ),
        API.graphql(graphqlOperation(listAdBanners)),
      ]);

      // @ts-ignore
      const currentArticle = articleRes?.data?.getArticle as
        | ArticleType
        | undefined;
      // @ts-ignore
      const adBannersList = adBannersRes?.data?.listAdBanners?.items || [];

      let relatedArticlesData: ArticleType[] = [];

      if (
        currentArticle?.relatedArticles &&
        currentArticle.relatedArticles.length > 0
      ) {
        // 関連記事を並列取得
        const relatedFetches = currentArticle.relatedArticles
          .map((relId: string | null | undefined) => {
            if (!relId) return null;
            return API.graphql(graphqlOperation(getArticle, { id: relId }));
          })
          .filter(Boolean);

        const relatedResults = await Promise.all(relatedFetches);

        relatedArticlesData = relatedResults
          .map((r: any) => r?.data?.getArticle)
          .filter((item: ArticleType | undefined) => item) as ArticleType[];
      }

      // 広告バナーのフィルタリングとソート
      const now = new Date();
      const filteredAdBanners: AdBanner[] = adBannersList.filter(
        (adBanner: any) => {
          return (
            adBanner?.ad?.status === "publish" &&
            isWithinInterval(now, {
              start: new Date(adBanner.ad.startDateTime),
              end: new Date(adBanner.ad.endDateTime),
            })
          );
        }
      );

      const sortedAdBanners = sortByPriority(filteredAdBanners);

      const groupedBanners: AdBannersType = {
        topheader: [],
        topfooter: [],
        ad: [],
        pr: [],
        articlefooter: [],
      };

      for (const banner of sortedAdBanners) {
        if (banner.position === "topheader") {
          groupedBanners.topheader.push(banner);
        } else if (banner.position === "topfooter") {
          groupedBanners.topfooter.push(banner);
        } else if (banner.position === "ad") {
          groupedBanners.ad.push(banner);
        } else if (banner.position === "pr") {
          groupedBanners.pr.push(banner);
        } else if (banner.position === "articlefooter") {
          groupedBanners.articlefooter.push(banner);
        }
      }

      setArticle(currentArticle);
      setRelatedArticles(relatedArticlesData);
      setAdBannersState(groupedBanners);
    })();
  }, [id]);

  return (
    <>
      {article && (
        <Article
          article={article}
          adBanners={adBannersState}
          relatedArticles={relatedArticles}
        />
      )}
    </>
  );
}
