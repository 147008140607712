import * as React from "react";
import Form from "components/organisims/Form";
import { publication } from "settings/FormSettings";
import * as Router from "@reach/router";
export interface ShowProps extends Router.RouteComponentProps {}

export default function Publication(props: ShowProps) {
  const { action } = Router.useParams();

  return (
    <Form dataType="publication" formParams={publication} useVersion={false} />
  );
}
