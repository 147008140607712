import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import { Router, useLocation } from "@reach/router";
import Amplify, { I18n } from "aws-amplify";
import awsconfig from "aws-exports";
import { withAuthenticator } from "aws-amplify-react";
import { Helmet } from "react-helmet";

import FullScreenDialog from "components/molecures/dialogs/FullScreenDialog";
import Ads from "components/pages/cms/Ads";
import Ad from "components/pages/cms/Ad";
import AdBanners from "components/pages/cms/AdBanners";
import AdBanner from "components/pages/cms/AdBanner";
import ArticlePickups from "components/pages/cms/ArticlePickups";
import ArticlePickup from "components/pages/cms/ArticlePickup";
import Articles from "components/pages/cms/Articles";
import Article from "components/pages/cms/Article";
import Authors from "components/pages/cms/Authors";
import Author from "components/pages/cms/Author";
import ContentOwner from "components/pages/cms/ContentOwner";

import Categores from "components/pages/cms/Categores";
import Category from "components/pages/cms/Category";
import Medias from "components/pages/cms/Medias";
import Media from "components/pages/cms/Media";
import PartialHtmls from "components/pages/cms/PartialHtmls";
import PartialHtml from "components/pages/cms/PartialHtml";
import Publications from "components/pages/cms/Publications";
import Publication from "components/pages/cms/Publication";
import Pages from "components/pages/cms/Pages";
import Page from "components/pages/cms/Page";
import Tags from "components/pages/cms/Tags";
import Tag from "components/pages/cms/Tag";

import Publish from "components/pages/cms/Publish";

import Layout from "components/layouts/CmsLayout";
import CmsApp from "apps/cms";
import "i18n";
import TopImages from "components/pages/cms/TopImages";
import TopImage from "components/pages/cms/TopImage";
import KaziMovies from "components/pages/cms/KaziMovies";
import KaziMovie from "components/pages/cms/KaziMovie";
import Footers from "components/pages/cms/Footers";
import Footer from "components/pages/cms/Footer";

function App() {
  React.useEffect(() => {
    Amplify.configure({
      ...awsconfig,
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    });
  }, []);

  const location = useLocation();
  let background;

  if (location.state && location.state.background) {
    background = location.state.background;
  }

  return (
    <CmsApp>
      <div className="App">
        <Helmet>
          <title>Kazi Online</title>
        </Helmet>
        <Router location={background || location}>
          <Layout path="/cms/editor">
            <Ads path="ads" />
            <Ad path="ads/:id/:action" />
            <AdBanner path="ads/:adID/adBanners/:id/:action" />
            <AdBanners path="ads/:adID/adBanners" />
            <ArticlePickups path="articlePickups" />
            <ArticlePickup path="articlePickups/:id/:action" />
            <Author path="authors/:id/:action" />
            <Authors path="authors" />
            <ContentOwner path="contentOwners/:id/:action" />
            <Articles path="articles" />
            <Articles path="categores/:categoryID/articles" />
            <Article path="articles/:id/:action" />
            <Article path="categores/:categoryID/articles/:id/:action" />
            <Categores path="categores" />
            <Category path="categores/:id/:action" />
            <Medias path="medias" />
            <Media path="medias/:id/:action" />
            <Tags path="tags" />
            <Tag path="tags/:id/:action" />
            <Pages path="pages" />
            <Page path="pages/:id/:action" />
            <TopImages path="topImages" />
            <TopImage path="topImages/:id/:action" />
            <PartialHtmls path="partialHtmls" />
            <PartialHtml path="partialHtmls/:id/:action" />
            <Publications path="publications" />
            <Publication path="publications/:id/:action" />
            <KaziMovies path="kaziMovies" />
            <KaziMovie path="kaziMovies/:id/:action" />
            <Footers path="footers" />
            <Footer path="footers/:id/:action" />
          </Layout>
        </Router>
        {background && (
          <>
            <FullScreenDialog
              fullScreen={
                !!location.pathname.includes("preview")
                // ||
                // !!location.pathname.includes("article")
              }
            >
              <Router basepath="/cms/editor" location={location}>
                <Ad path="ads/:id/:action" />
                <Page path="pages/:id/:action" />
                <Author path="authors/:id/:action" />
                <ContentOwner path="contentOwners/:id/:action" />
                <Category path="categores/:id/:action" />
                <ArticlePickup path="articlePickups/:id/:action" />
                <AdBanner path="ads/:adID/adBanners/:id/:action" />
                <Media path="medias/:id/:action" />
                <Tag path="tags/:id/:action" />
                <Article path="articles/:id/:action" />
                <Publish path="articles/:id/publish" />
                <TopImage path="topImages/:id/:action" />
                <PartialHtml path="partialHtmls/:id/:action" />
                <KaziMovie path="kaziMovies/:id/:action" />
                <Publication path="publication/:id/:action" />
                <Footer path="footers/:id/:action" />
              </Router>
            </FullScreenDialog>
          </>
        )}
        {background && (
          <>
            {/* <FullScreenDialog fullScreen>
              <Router basepath="/cms/editor" location={location}>
                <ManuscriptPreview path="articles/:id/preview" />
              </Router>
            </FullScreenDialog> */}
          </>
        )}
      </div>
    </CmsApp>
  );
}

// @todo 仮：日本語
const dict = {
  ja: {
    "User does not exist.": "ユーザー名またはパスワードが正しくありません",
    "Incorrect username or password.":
      "ユーザー名またはパスワードが正しくありません",
    "Password did not conform with policy: Password not long enough":
      "パスワードが短すぎます",
    "Invalid session for the user, session is expired.": "セッションが無効です",
    "Password attempts exceeded":
      "パスワードを一定回数以上間違えたため、アカウントを無効にしました",
    "Account recovery requires verified contact information":
      "アカウントを復旧するには連絡先の確認が必要です",
    "Back to Sign In": "サインイン画面へ戻る",
    "Change Password": "パスワード変更",
    Change: "変更",
    Code: "確認コード",
    "Confirm a Code": "コードを確認する",
    "Confirm Sign In": "確認",
    "Confirm Sign Up": "サインアップ",
    Confirm: "確認",
    Email: "メールアドレス",
    "Forgot Password": "パスワードをお忘れの場合",
    "Loading...": "ロード中...",
    "New Password": "新しいパスワード",
    "Reset password": "パスワードリセット",
    "No MFA": "MFAなし",
    Password: "パスワード",
    "Phone Number": "電話番号",
    "Pick a File": "ファイルを選択する",
    "Resend a Code": "確認コードを再送する",
    "Resend Code": "確認コードを再送する",
    "Select MFA Type": "MFAタイプの選択",
    "Select your preferred MFA Type": "MFAタイプを選択してください",
    "Sign In Account": "サインイン",
    "Sign In": "サインイン",
    "Sign in": "サインイン",
    "Sign Out": "サインアウト",
    "Sign Up Account": "サインアップ",
    "Sign Up": "サインアップ",
    "Create account": "サインアップ",
    Skip: "スキップする",
    Submit: "保存",
    Username: "ユーザー名",
    "Verify Contact": "確認",
    Verify: "確認する",
    "Create a new account": "サインアップ",
    "Sign in to your account": "サインイン",
    "No account? ": "アカウントがない",
    "Forget your password? ": "パスワードを忘れた",
    "Have an account? ": "アカウントがある",
    "Create Account": "アカウント作成",
  },
};
I18n.putVocabularies(dict);
I18n.setLanguage("ja");
// Hub.listen("auth", ({ payload: { event, data } }) => {
//   switch (event) {
//     case "configured":
//     case "signUp":
//     case "signOut":
//     case "signIn_failure":
//     case "signIn":
//       initUser();
//       break;
//   }
// });
// @ts-ignore
export default withAuthenticator(App, {
  signUpConfig: {
    hiddenDefaults: ["phone_number"],
  },
});
