import React from "react";
import Table from "components/molecures/Table";
import * as Router from "@reach/router";
type ShowProps = {
  adID?: String;
} & Router.RouteComponentProps;

function AdBanners(props: ShowProps) {
  const { adID } = props;
  const variables = {
    filter: {
      adID: { eq: adID },
    },
  };
  return <Table type="adBanners" variables={variables} />;
}

export default AdBanners;
