import * as React from "react";
import Form from "components/organisims/Form";
import { tag } from "settings/FormSettings";
import * as Router from "@reach/router";
import { API, graphqlOperation } from "aws-amplify";
export interface ShowProps extends Router.RouteComponentProps {}

export default function Tag(props: ShowProps) {
  // @see connection先のtagが削除された後なので、tag参照があるクエリーは使えない
  const listArticleTags = `
    query ListArticleTags(
        $filter: ModelArticleTagFilterInput
    ) {
      listArticleTags(filter: $filter) {
        items {
          id
          version
        }
      }
    }
    `;
  const deleteArticleTag = `
    mutation DeleteArticleTag(
      $input: DeleteArticleTagInput!
    ) {
      deleteArticleTag(input: $input) {
        id
      }
    }
  `;

  const afterAction = async (action: string, data: any) => {
    if (action === "delete") {
      const res = await API.graphql(
        graphqlOperation(listArticleTags, {
          filter: { tagID: { eq: data.id } },
        })
      );
      res.data.listArticleTags.items.map(async (item) => {
        await API.graphql(
          // @ts-ignore
          graphqlOperation(deleteArticleTag, {
            input: {
              id: item.id,
              expectedVersion: Number(item.version),
            },
          })
        );
      });
    }
  };
  return <Form dataType="tag" formParams={tag} afterAction={afterAction} />;
}
