import React from "react";
import * as Router from "@reach/router";
import Table from "components/molecures/Table";
export interface ShowProps extends Router.RouteComponentProps {}

function Medias(props: ShowProps) {
  const variables = {
    limit: 6000,
  };
  return (
    <>
      <Table type="medias" hiddeDelete={true} variables={variables} />
    </>
  );
}

export default Medias;
