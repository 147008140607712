import * as React from "react";
import Button from "@material-ui/core/Button";
import { API, graphqlOperation } from "aws-amplify";
import { getArticle } from "../../../graphql/queries";
import { updateArticle } from "graphql/mutations";
import * as Router from "@reach/router";
export interface ShowProps extends Router.RouteComponentProps {}

export default function Publish(props: ShowProps) {
  const handleClose = () => {
    saveDate();
    window.history.back();
  };

  const isPublish = () => {
    return props.location.state.status === "publish";
  };

  const publis = async (id: string) => {
    const draft = await API.graphql(
      // @ts-ignore
      graphqlOperation(getArticle, { id: id })
    );
    API.graphql(
      // @ts-ignore
      graphqlOperation(updateArticle, {
        input: {
          id: id,
          status: "publish",
          startDateTime: draft.data.getArticle.startDateTime,
          expectedVersion: Number(draft.data.getArticle.version),
        },
      })
    );
  };

  const draft = async (id: string) => {
    const _public = await API.graphql(
      // @ts-ignore
      graphqlOperation(getArticle, { id: id })
    );
    API.graphql(
      // @ts-ignore
      graphqlOperation(updateArticle, {
        input: {
          id: id,
          startDateTime: _public.data.getArticle.startDateTime,
          status: "draft",
          expectedVersion: Number(_public.data.getArticle.version),
        },
      })
    );
  };

  const saveDate = () => {
    if (isPublish()) {
      draft(props.id);
    } else {
      publis(props.id);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Button onClick={handleClose} color="primary" autoFocus>
        {isPublish() ? "非公開にする" : "公開する"}
      </Button>
    </div>
  );
}
