import React from "react";
import { useSelector } from "react-redux";
import Form from "components/organisims/Form";
import { article } from "settings/FormSettings";
import * as Router from "@reach/router";
import PreviewArticle from "components/pages/cms/preview/Article";
import { API, graphqlOperation } from "aws-amplify";
import { createArticleTagNew, deleteArticleTagNew } from "graphql/mutations";
import { listTagByArticle, searchArticles } from "graphql/queries";
import { updateArticle } from "../../../graphql/mutations";

export interface ShowProps extends Router.RouteComponentProps {}

export default function Article(props: ShowProps) {
  const { action, ...rest } = Router.useParams();
  const { userAttributes } = useSelector((state: any) => state.user) as any;

  const afterAction = async (action: string, data: any) => {
    const res = await API.graphql(
      graphqlOperation(listTagByArticle, {
        articleID: data.id,
      })
    );
    const myTags = res?.data?.listTagByArticle.items;
    const tags = data.tags;
    if (action !== "delete") {
      if (Array.isArray(tags)) {
        const tasks = [];
        // タグ追加、保存ずみのタグになければ作成
        tags.forEach((item) => {
          const checkTags = myTags.filter(
            (myItem) => item.tag.id === myItem.tag.id
          );
          if (checkTags.length === 0) {
            tasks.push(
              API.graphql(
                // @ts-ignore
                graphqlOperation(createArticleTagNew, {
                  input: {
                    id: data.id + item.tag.id,
                    articleID: data.id,
                    tagID: item.tag.id,
                  },
                })
              )
            );
          }
        });
        // タグ追加になく、保存ずみのタグにあれば削除
        myTags.forEach((myItem) => {
          const checkTags = tags.filter(
            (item) => item.tag.id === myItem.tag.id
          );

          if (checkTags.length === 0) {
            tasks.push(
              API.graphql(
                // @ts-ignore
                graphqlOperation(deleteArticleTagNew, {
                  input: {
                    id: data.id + myItem.tag.id,
                  },
                })
              )
            );
          }
        });
        const updated = await Promise.all(tasks);
      }
    }

    if (action === "new") {
      await API.graphql(
        // @ts-ignore
        graphqlOperation(updateArticle, {
          input: {
            id: data.id,
            connectionID: data.id,
            expectedVersion: 1,
          },
        })
      );
    }
  };

  const customValidate = async (data: any) => {
    let filter = { slug: { eq: data.slug } };
    if ("id" in data) {
      filter["id"] = { ne: data.id };
    }
    // @ts-ignore
    const res = await API.graphql(
      graphqlOperation(searchArticles, {
        limit: 1000,
        filter: filter,
      })
    );
    if (res.data.searchArticles.items.length > 0) {
      alert("スラッグが重複しています");
      throw new Error("duplicate entry for slug");
    }
  };

  return (
    <>
      {action !== "preview" && userAttributes && (
        <Form
          dataType="article"
          formParams={article}
          // hiddenCreateInputs={{
          //   authorID: userAttributes.sub,
          // }}
          afterAction={afterAction}
          customValidate={customValidate}
        />
      )}

      {action === "preview" && <PreviewArticle {...rest} />}
    </>
  );
}
