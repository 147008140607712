import * as React from "react";
import Form from "components/organisims/Form";
import { youtube } from "settings/FormSettings";
import * as Router from "@reach/router";
export interface ShowProps extends Router.RouteComponentProps {}

export default function KaziMovie(props: ShowProps) {
  const { action } = Router.useParams();

  return <Form dataType="youtube" formParams={youtube} useVersion={false} />;
}
