import React from "react";
import Table from "components/molecures/Table";
import { useSelector } from "react-redux";
import * as Router from "@reach/router";
import { useDispatch } from "react-redux";
import common from "features/common/commonSlice";
import { listArticleTagNews } from "graphql/queries";
import { API, graphqlOperation } from "aws-amplify";

type ShowProps = {
  categoryID?: String;
} & Router.RouteComponentProps;

function Articles(props: ShowProps) {
  const dispatch = useDispatch();
  const customQuery = `
  query MyQuery($nextToken: String, $limit: Int) {
    listArticles(nextToken: $nextToken, limit: $limit) {
      items {
        id
        status
        slug
        startDateTime
        updatedAt
        title
        createdAt
      }
      nextToken
    }
  }  
  `;
  const { groups } = useSelector((state: any) => state.user) as any;
  const { categoryID } = props;
  let variables;
  if (categoryID) {
    variables = {
      // limit: 50,
      filter: {
        categoryID: { eq: categoryID },
      },
    };
  } else {
    variables = {
      // limit: 50,
    };
  }
  return (
    <Table
      type="articles"
      customQuery={customQuery}
      enableDelete={async (rowData: any) => {
        const res = await API.graphql(
          graphqlOperation(listArticleTagNews, {
            filter: { articleID: { eq: rowData.id } },
          })
        );
        if (res.data.listArticleTagNews.items.length === 0) {
          return true;
        }

        dispatch(
          common.actions.fetchFailure({
            errors: [
              {
                message:
                  "タグが設定されている記事は削除できません。タグを外してから削除してください。",
              },
            ],
          })
        );
        return false;
      }}
      variables={variables}
      publish={groups.includes("Admins")}
      preview={true}
    />
  );
}

export default Articles;
