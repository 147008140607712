import React from "react";
import Table from "components/molecures/Table";
import * as Router from "@reach/router";
import { useDispatch } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import common from "features/common/commonSlice";
import { listAdBanners } from "graphql/queries";
export interface ShowProps extends Router.RouteComponentProps {}

function Ads(props: ShowProps) {
  const dispatch = useDispatch();
  return (
    <>
      <Table
        type="ads"
        childList="adBanners"
        enableDelete={async (rowData: any) => {
          const res = await API.graphql(
            graphqlOperation(listAdBanners, {
              filter: { adID: { eq: rowData.id } },
            })
          );
          if (res.data.listAdBanners.items.length === 0) {
            return true;
          }
          dispatch(
            common.actions.fetchFailure({
              errors: [{ message: "先に紐づくバナーを削除してください。" }],
            })
          );
          return false;
        }}
      />
    </>
  );
}

export default Ads;
